$(() => {
  $('.submit-on-change').change(function(){
    $(this).closest('form').submit()
  })
})

$(() => {
  $(".js-log-retrieve").change(function(){
    const type = $(this).data("type")
    const serial = $(this).data("serial")
    const url = `/csi_serials/get_log?get_type=${type}&serial_no=${serial}`
    $(this).closest("td").find("a").attr("href", url)
  })

  $(".js-log-retrieve-link").click(function() {
    fetch($(this).attr("href"))

      alert("The latest log has been requested.\nIt will be available from [Files] in the Machine List in about 15 minutes.\nTime may vary due to the unstable network environment or file size.")

    return false;
  })

  $(".js-spec-retrieve-link").click(function() {
    fetch($(this).attr("href"))
    alert("The latest spec has been requested.")
    return false;
  })
})


// ファイルアップロード時にsubmit
$(() => {
  $('#file-field').change(function() {
    if ($(this)[0].files.length > 0) {
      // ファイルが選択されたらフォームを送信
      $(this).closest('form').submit();
    }
  });
});
