// tooltipをONにする
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

// popoverをONにする
$(function () {
  $('[data-toggle="popover"]').popover()
})

