// ===============================
// JS
// ===============================
// ライブラリ
require('jquery')
require("@rails/ujs").start()
require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")


// 自前
require("javascripts/utils")
require("javascripts/bootstrap_custom")


// ===============================
// CSS
// ===============================
// ライブラリ
require("bootstrap/scss/bootstrap")
require("@fortawesome/fontawesome-free/scss/fontawesome")

// 自前
require("stylesheets/base")
require("stylesheets/utils")
require("stylesheets/navbar")
require("stylesheets/table-horizontal-scroll")

// ===============================
// 画像
// ===============================
require.context("../images", true)
